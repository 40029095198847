// eslint-disable-next-line object-curly-newline
import { mdiAccountMultipleOutline, mdiAccountOutline, mdiCardTextOutline, mdiFileSign, mdiFrequentlyAskedQuestions, mdiHandshakeOutline, mdiHomeOutline, mdiTableArrowLeft } from '@mdi/js'

export default [
  {
    title: 'Tableau de bord',
    icon: mdiHomeOutline,
    to: 'home',
    resource: 'superAdmin',
  },
  {
    subheader: 'APPLICATION',
  },
  {
    title: 'Adhérents',
    icon: mdiAccountMultipleOutline,
    to: 'liste-adherents',
    resource: 'Admin',
  },
  {
    title: 'Importer',
    icon: mdiTableArrowLeft,
    to: 'import-adherents',
    resource: 'Admin',
  },
  {
    title: 'CGP',
    icon: mdiHandshakeOutline,
    to: 'liste-cgp',
    resource: 'superAdmin',
  },
  {
    title: 'Utilisateurs',
    icon: mdiAccountOutline,
    to: 'users',
    resource: 'superAdmin',
  },
  {
    subheader: 'SITE WEB',
  },
  {
    title: 'Texte d\'intro',
    icon: mdiCardTextOutline,
    to: 'web-intro',
    resource: 'superAdmin',
  },
  {
    title: 'FAQ',
    icon: mdiFrequentlyAskedQuestions,
    children: [
      {
        title: 'Catégories',
        to: 'web-faq-categories',
        resource: 'superAdmin',
      },
      {
        title: 'Questions',
        to: 'web-faq',
        resource: 'superAdmin',
      },
    ],
  },
  {
    title: 'Lettres de mission',
    icon: mdiFileSign,
    to: 'lettres-mission',
    resource: 'superAdmin',
  },
]
