import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/plugins/acl/routeProtection'
import pages from './pages'

Vue.use(VueRouter)

let userData = JSON.parse(sessionStorage.getItem('userData'))
const userRole = userData && userData.role ? userData.role : null

const routes = [
  {
    path: '/',
    redirect: userRole === 'admin' ? '/adherents/liste' : 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      resource: 'Admin',
      layout: 'content',
    },
  },
  {
    path: '/adherents/liste',
    name: 'liste-adherents',
    component: () => import('@/views/application/adherents/AdherentsList.vue'),
    meta: {
      resource: 'Admin',
      layout: 'content',
    },
  },

  {
    path: '/adherents/import',
    name: 'import-adherents',
    component: () => import('@/views/application/adherents/Import.vue'),
    meta: {
      resource: 'Admin',
      layout: 'content',
    },
  },
  {
    path: '/adherents/modification/:id',
    name: 'modif-adherents',
    props: true,
    component: () => import('@/views/application/adherents/AdherentsAdd.vue'),
    meta: {
      resource: 'Admin',
      layout: 'content',
    },
  },
  {
    path: '/cgp/liste',
    name: 'liste-cgp',
    component: () => import('@/views/application/cgp/List.vue'),
    meta: {
      resource: 'superAdmin',
      layout: 'content',
    },
  },
  {
    path: '/profil',
    name: 'app-user-view',
    component: () => import('@/views/application/users/Profil.vue'),
    meta: {
      resource: 'Admin',
      layout: 'content',
    },
  },
  {
    path: '/utilisateurs',
    name: 'users',

    // component: () => import('@/views/application/users/UsersList.vue'),
    component: () => import('@/views/application/users/List.vue'),
    meta: {
      resource: 'superAdmin',
      layout: 'content',
    },
  },
  {
    path: '/site/intro',
    name: 'web-intro',
    component: () => import('@/views/application/website/IntroText.vue'),
    meta: {
      resource: 'superAdmin',
      layout: 'content',
    },
  },
  {
    path: '/site/faq',
    name: 'web-faq',
    component: () => import('@/views/application/website/Faq.vue'),
    meta: {
      resource: 'superAdmin',
      layout: 'content',
    },
  },
  {
    path: '/site/categories-faq',
    name: 'web-faq-categories',
    component: () => import('@/views/application/website/FaqCategories.vue'),
    meta: {
      resource: 'superAdmin',
      layout: 'content',
    },
  },
  {
    path: '/site/lettres-mission',
    name: 'lettres-mission',
    component: () => import('@/views/application/lettres-mission/Index.vue'),
    meta: {
      resource: 'superAdmin',
      layout: 'content',
    },
  },
  {
    path: '/identification',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/mot-de-passe-oublie',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  // userData = sessionStorage.getItem('userData')
  userData = JSON.parse(sessionStorage.getItem('userData'))

  const isLoggedIn = userData && sessionStorage.getItem('accessToken') && sessionStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  if (to.name === 'home' && userData.role !== 'superAdmin') {
    next('/adherents/liste')
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    if (userRole === 'admin') {
      next('/adherents/liste')
    } else {
      next('/')
    }
  }

  return next()
})

export default router
