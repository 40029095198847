import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg',
})
