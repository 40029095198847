// axios
import themeConfig from '@themeConfig'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${themeConfig.app.baseURL}api/`,
  timeout: 10000,
  headers: { Accept: 'application/json' },
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = sessionStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
